import React from 'react';
import SocialLinks from "./footer-social-links"
import footerLogo from "../images/trybe_logo_footer.svg"

const renderFooterMenu = () => (
  <div className="internal-links">
    <ul className="footer-menu">
      <li><a href="https://www.betrybe.com/politica-de-privacidade">Política de Privacidade</a></li>
      <li><a href="https://www.betrybe.com/termos-de-uso">Termos de Uso</a></li>
      <li><a href="#config-consent">Desabilitar Cookies</a></li>
    </ul>
  </div>
)

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-logo">
          <img src={footerLogo} alt="Logo da Trybe" />
        </div>
        <SocialLinks />
        {renderFooterMenu()}
      </div>
      <div className="footer-bottom">
        <span>Trybe Desenvolvimento de Software LTDA  |  CNPJ 34.389.271/0001-00</span>
      </div>
    </footer>
  )
}

export default Footer
