import React, { useState } from 'react';
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Header from "./header"
import Sidemenu from "./sidemenu"
import Submenu from "./submenu"
import Footer from "./footer"

const renderLeftCard = () => (
  <div className="card left-card">
    <div>
      A Trybe é uma escola de
      programação web que investe
      em você!
    </div>
    <a href="https://betrybe.com">Conheça a Trybe!</a>
  </div>
)

const renderRightCard = () => (
  <div className="card right-card">
    <div>
      Quer trabalhar com edução e tecnologia,
      gerando oportunidades e transformando
      positivamente a vida das pessoas?
    </div>
    <a href="https://www.betrybe.com/trabalhe-conosco">Trabalhe conosco!</a>
  </div>
)

const Layout = ({ children, location }) => {
  const toggleSideMenu = () => {
    setShowSideMenu(!showSideMenu)
  }
  const [showSideMenu, setShowSideMenu] = useState(false);

  const renderOverlay = () => {
    const classNames = `side-menu-overlay ${showSideMenu ? "side-menu-overlay-show" : ""}`
    return (
      <div onClick={toggleSideMenu} className={classNames}></div>
    )
  }
  return (
    <>
      <Header toggleSideMenu={toggleSideMenu} />
      {renderOverlay()}
      <Sidemenu showSideMenu={showSideMenu}>
        <Submenu name="Geradores" pathname={location.pathname} toggleSideMenu={toggleSideMenu}>
          <Link to="/gerador/cpf">CPF</Link>
        </Submenu>
      </Sidemenu>
      <div className="container">
        <main className="content">
          <div className="right-column">
            {children}
            {renderLeftCard()}
            {renderRightCard()}
          </div>
        </main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.func.isRequired,
}

export default Layout
