import React from 'react';

const Sidemenu = ({ showSideMenu, children }) => {
  return (
    <nav className={`side-menu ${showSideMenu ? 'side-menu-show' : ''}`}>
      <div className="side-menu-title">
        <span>Ferramentas</span>
      </div>
      <div className="side-menu-divider">
        <span></span>
      </div>
      <ul className="submenus">
        { children }
      </ul>
    </nav>
  )
}

export default Sidemenu
