import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Button } from "@betrybe/ada-react/atoms"
import MenuIcon from '@material-ui/icons/Menu';

const Header = ({ toggleSideMenu }) => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo_trybe.png" }) {
        childImageSharp {
          fixed(width: 24, height: 27) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <nav className="header">
      <div className="brand">
        <MenuIcon onClick={toggleSideMenu} className="toggle" />
        <Link to="/">
          <Img fixed={logo.childImageSharp.fixed} alt="Logo da Trybe" />
        </Link>
        <Link to="/">
          <p>DevTools</p>
        </Link>
      </div>
      <Button kind="secondary" size="small" url="https://betrybe.com" className="cta">Conheça a Trybe</Button>
    </nav>
  )
}

export default Header
