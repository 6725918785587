import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Submenu = ({ name, children, pathname, toggleSideMenu }) => {

  const getSubmenuItems = (children, pathname) => {
    let openMenu = false
    const submenuItems = React.Children.map(children, (child) => {
      const active = pathname === child.props.to || pathname === `${child.props.to}/`
      openMenu = openMenu || active
      const classNames = `submenu-item ${active ? 'submenu-item-active' : ''}`
      return (
        <li onClick={toggleSideMenu} className={classNames}>{child}</li>
      )
    })
    return { render: submenuItems, open: openMenu }
  }

  const submenuItems = getSubmenuItems(children, pathname)

  const [showSubMenu, setShowSubMenu] = useState(submenuItems.open);

  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu)
  }

  return (
    <>
      <li className="submenu" onClick={toggleSubMenu}>
        <span className="submenu-label">{name}</span>
        <span className="submenu-arrow">
          <ExpandMoreIcon className="submenu-arrow-expand" />
        </span>
      </li>
      <ul className={`submenu-items ${showSubMenu ? 'submenu-items-show' : ''}`}>
        {submenuItems.render}
      </ul>
    </>
  )
}

export default Submenu
